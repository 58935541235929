<template>
  <div class="full green">
    <van-nav-bar :title="type === 'add' ? '添加出行人':'修改出行人'" >
      <template #left>
        <van-icon :size="24" color="#ffffff" name="arrow-left" slot="left" @click="exit()" />
      </template>
    </van-nav-bar>
    <div class="content">
      <div class="p_info">
        <div class="item d_f ali_c bb">
          <div class="name">中文姓名</div>
          <input class="flex_1" v-model="perInfo.data.linkManName" type="text" placeholder="须与证件上的名字一致" @change="isName">
        </div>
        <div class="item d_f ali_c bb">
          <div class="name">联系电话</div>
          <input class="flex_1" v-model="perInfo.data.phoneNum" type="text" placeholder="请输入联系电话" @change="isPhoneNumber">
        </div>
        <div class="item d_f ali_c">
          <div class="name">证件号码</div>
          <input class="flex_1" v-model="perInfo.data.cardNum" type="text" placeholder="请填写身份证号码" @change="isIDcard">
        </div>
      </div>
      <div class="p_btn cur_p" @click="addPer">
        完成
      </div>
    </div>
  </div>
</template>

<script>
  // 证件号码,json 对象,属性key是证件类型, value 是证件号码 如 {0=xxxxx} 表示, 身份证号为 'xxxxx',其中0表示身份证，1表示护照，2表示军官证，3表示台胞证，4表示港澳通行证，5表示大陆居民往来台湾通行证

  import { NavBar, Icon, Toast } from 'vant'
  export default {
    name: "person",
    components:{
      'van-nav-bar': NavBar,
      'van-icon': Icon,
    },
    data() {
      return {
        type:this.$route.query.type,//新增货编辑
        apis:{
          add:'/userLinkMan/saveLinkMan',
          edit:'/userLinkMan/updateLinkMan',
          findLinkMan:'/userLinkMan/findLinkMan',
        },
        linkManId:this.$route.query.key || null,
        userId:this.$route.query.userId || null,
        perInfo:{
          data:{
            linkManName:'',
            phoneNum:'',
            cardType:'0',
            cardNum:'',
          },
          token:this.$global.token,
        },
        isClick:true,//重复点击
      }
    },
    created(){
      if(this.$route.query.key){
        this.perDetail();
      }
    },
    methods: {
      // 获取详情
      perDetail(){
        let me = this;
        me.$post({
          url:me.apis.findLinkMan,
          params: {
            linkManId: me.linkManId,
            token: me.$global.token
          }
        }).then(rsp => {
          let data = rsp.data;
          this.perInfo.data.linkManName = data.linkManName;
          this.perInfo.data.cardType = data.cardType;
          this.perInfo.data.cardNum = data.cardNum;
          this.perInfo.data.phoneNum = data.phoneNum;
        });
      },
      // 验证姓名
      isName() {
          let reg = /^[\u4E00-\u9FA5\uf900-\ufa2d·s]{2,20}$/
          this.perInfo.data.linkManName = this.perInfo.data.linkManName.replace(/\s/g,"")
          if(!reg.test(this.perInfo.data.linkManName)){
              this.$toast('姓名输入有误！')
          }
      },
      // 验证手机号
      isPhoneNumber() {
          let reg = /^1[3456789]\d{9}$/
          this.perInfo.data.phoneNum = this.perInfo.data.phoneNum.replace(/\s/g,"")
          if(!reg.test(this.perInfo.data.phoneNum)){
              this.$toast('手机输入有误！')
          }
      },
      // 验证身份证号
      isIDcard() {
          let reg = /^[1-9]\d{7}((0\d)|(1[0-2]))(([0|1|2]\d)|3[0-1])\d{3}$|^[1-9]\d{5}[1-9]\d{3}((0\d)|(1[0-2]))(([0|1|2]\d)|3[0-1])\d{3}([0-9]|X)$/
          this.perInfo.data.cardNum = this.perInfo.data.cardNum.replace(/\s/g,"")
          if(!reg.test(this.perInfo.data.cardNum)){
              this.$toast('身份证号码输入有误！')
          }
      },
      //新增货编辑
      addPer(){
        let me = this;
        /*let isPass = true;
        for(let i in me.perInfo.data){
          if(!me.perInfo.data[i]){
            isPass = false;
          }
        }*/
        let regName = /^[\u4E00-\u9FA5\uf900-\ufa2d·s]{2,20}$/;//名字正则
        let regPhone = /^1[3456789]\d{9}$/;//电话正则
        let regCard = /^[1-9]\d{7}((0\d)|(1[0-2]))(([0|1|2]\d)|3[0-1])\d{3}$|^[1-9]\d{5}[1-9]\d{3}((0\d)|(1[0-2]))(([0|1|2]\d)|3[0-1])\d{3}([0-9]|X)$/;//身份证正则
        if(!me.perInfo.data.linkManName) return Toast('请输入中文姓名');
        if(!regName.test(me.perInfo.data.linkManName)) return Toast('姓名输入有误');
        if(!me.perInfo.data.phoneNum) return Toast('请输入联系电话');
        if(!regPhone.test(me.perInfo.data.phoneNum)) return Toast('电话输入有误');
        if(!me.perInfo.data.cardNum) return Toast('请输入证件号码');
        if(!regCard.test(me.perInfo.data.cardNum)) return Toast('证件号输入有误');
        // if(!isPass) return Toast('输入不完整');
        if(!me.isClick) return;
        me.isClick = false;
        if(me.linkManId){
          this.perInfo.data.linkManId = me.linkManId;
          this.perInfo.data.userId = me.userId;
        }
        let url = me.linkManId ? me.apis.edit : me.apis.add;
        me.$post({
          url:url,
          params: this.perInfo
        }).then(res => {
          me.isClick = true;
          Toast('操作成功');
          me.$global.isAddPerson = true;
          me.perInfo = {
            data:{
              linkManName:'',
                phoneNum:'',
                cardType:'身份证',
                cardNum:'',
            },
            token:me.$global.token,
          };
          me.$router.go(-1);
        }).catch(err => {
          Toast(err.message);
          me.isClick = true;

        });
      },
      exit(){
        // this.$destroy('KeepAlive')
        // sessionStorage.clear();
        // sessionStorage.setItem('LOGIN_STATUS',2);
        // sessionStorage.setItem('token',this.$global.token);
        this.$router.go(-1)
      },
    }
  }
</script>

<style scoped lang="scss">
  .full{
    .p_btn{
      height:40px;
      background:rgba(63,212,103,1);
      border:1px solid rgba(63,212,103,1);
      border-radius:5px;
      line-height: 40px;
      text-align: center;
      font-size:16px;

      font-weight:bold;
      color:rgba(255,255,255,1);
    }
    .p_info{
      .item{
        .name{
          width: 82px;
          font-size:14px;

          font-weight:500;
          color:rgba(20,18,18,1);
        }
        input{
          border: none;
          outline: none;
          font-size:14px;

          font-weight:400;
          color:rgba(174,174,174,1);
          position: relative;
          top: 1px;
        }
        padding: 0 10px;
        height: 43px;
      }
      .bb{
        border-bottom: 1px solid #E3E3E3;
      }
      margin-bottom: 19px;
      background:rgba(255,255,255,1);
      border-radius:5px;
    }
    overflow: hidden;
    background:rgba(238,238,238,1);
  }
  .content{
    padding: 10px 20px;
    height: calc(100% - 46px);
  }
  .van-nav-bar{
    background-color: #3FD467;
    &::after{
      border: none;
    }
  }
</style>
